<template>
  <pull-to
    :top-load-method="onRefresh"
    :pullText="'1 - Arraste e Solte para Atualizar!'"
    :triggerText="2"
    :top-config="TOP_DEFAULT_CONFIG"
  >
    <div class="container-fluid text-left">
      <div class="row">
        <div class="col-12 p-3">
          <h4 style="w-100">
            <i class="fa fa-motorcycle"></i> Entregas
            {{ $route.name == "Realizados" ? "Realizados" : "" }}
            <b-spinner
              v-if="loading"
              small
              class="float-right"
              style="font-weight: none"
              variant="secondary"
            />
          </h4>
        </div>
      </div>

      <div
        class="text-danger"
        style="font-size: 19px; text-align: center"
        v-if="alertaConexao"
      >
        <i class="fa fa-exclamation-triangle" style="font-size: 30px"></i>
        <br />
        SEM CONEXÃO COM SERVIDOR
      </div>

      <div class="row">
        <div
          class="col-12 text-center p-4"
          v-if="loading && pedidos.length <= 0"
        >
          <b-spinner v-if="loading" large style="" />
        </div>
        <div v-if="!loading && pedidos.length <= 0" class="text-center">
          <img
            src="@/assets/undraw_Drone_delivery_re_in95.png"
            style="width: 100%"
          />
          Nenhuma disponível ainda.
        </div>
        <div
          class="col-12 col-sm-5  col-lg-4 mb-2 pedido ml-sm-1"
          v-for="pedido in pedidos"
          :key="pedido.senha"
        >
          <div class="row titulo">
            <div class="col-6 pt-2">
              <h2 class="bold text-left mb-0" style="font-weight: bold">
                Nº {{ pedido.senha }}
                <small style="font-size:11px" v-if="pedido.sistema == 'IFOOD'">
                  <small>IFOOD #{{ pedido.id_sistema }}</small>
                </small>
                <br />
              </h2>
            </div>
            <div class="col-6 pt-2 text-right" style="word-break: break-word">
              <h6 class="mb-0 pb-0">
                <b>{{ pedido.bairro }}</b>
              </h6>
              <small
                ><small>{{ pedido.emp }}</small></small
              >
            </div>
            <div class="col-12" @click="openCliente(pedido.dados_cliente)">
              <small>
                <small>
                  Pedido
                  {{ pedido.data | moment("DD/MM HH:mm") }}
                  <span
                    v-if="pedido.dados_cliente && pedido.dados_cliente.Nome"
                  >
                    <i class="fa fa-user ml-3 mr-1" />
                    {{
                      pedido.dados_cliente.Nome.length > 20
                        ? pedido.dados_cliente.Nome.substr(0, 20)
                        : pedido.dados_cliente.Nome || ""
                    }}
                    <span
                      v-if="
                        pedido.dados_cliente.Telefone &&
                          pedido.dados_cliente.Telefone != ''
                      "
                      style="color:blue; text-decoration: underline"
                    >
                      <i class="fab fa-whatsapp ml-3 mr-1" />
                      {{ pedido.dados_cliente.Telefone || "" }}
                    </span>
                  </span>
                </small>
              </small>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 mt-2"
              v-if="pedido.endereco && pedido.endereco != ''"
            >
              <b-icon-map />
              <small id="enderecoDiv" ref="enderecoDiv">
                {{ pedido.endereco }}
                <span v-if="pedido.cep && pedido.cep.length > 0">
                  <br />
                  CEP: {{ pedido.cep }}
                </span>

                <span
                  @click="copiarEndereco(pedido.endereco)"
                  style="color:blue; text-decoration: underline"
                >
                  <i
                    class="fa fa-copy ml-3"
                    v-if="pedido.endereco && pedido.bairro"
                  ></i>
                  copiar endereço
                </span>
              </small>
              <hr class="mb-1 pb-0" />
            </div>
            <div
              class="
                col-12
                mt-2
                mb-2
                text-center
                d-flex
                justify-content-between
              "
              v-if="pedido.qtd_pizza > 0 || pedido.qtd_refri > 0"
            >
              <div class="w-50">
                <i class="fa fa-pizza-slice"></i>
                Pizzas
                <br />
                <h3>
                  <b>{{ pedido.qtd_pizza }}</b>
                </h3>
              </div>
              <div class="w-50">
                <i class="fa fa-wine-bottle"></i> Bebidas
                <br />
                <h3>
                  <b>
                    {{ pedido.qtd_refri }}
                  </b>
                </h3>
              </div>
            </div>

            <div class="col-12 text-left">
              <hr class="mb-3 mt-0" />
              <div class="d-flex justify-content-between">
                <div>
                  <b-icon-cash /> {{ pedido.pagamento }}
                  <small v-if="pedido.vl_troco > 0" class="text-danger">
                    <small>
                      <br />
                      Troco P/ R$ {{ pedido.vl_dinheiro | currency }} [R$
                      {{ pedido.vl_troco | currency }}]
                    </small>
                  </small>
                </div>
                <div>
                  <b>R$ {{ pedido.vl_total | currency }}</b>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row"
            v-if="
              pedido.sistema == 'IFOOD' &&
                pedido.integration_data &&
                pedido.integration_data.customer &&
                pedido.integration_data.customer.phone &&
                pedido.integration_data.customer.phone.localizer
            "
            @click="
              copiarLocalizador(
                pedido.integration_data.customer.phone.localizer.toString()
              )
            "
          >
            <div class="col-12 bg-danger text-white text-center p-3 mt-2">
              Código Localizador (IFOOD)<br />{{
                pedido.integration_data.customer.phone.localizer
              }}
              <br />
              <small>
                <i
                  class="fa fa-copy mr-1"
                  v-if="pedido.endereco && pedido.bairro"
                ></i
                >Clique para copiar</small
              >
            </div>
            <div class="col-12 bg-danger" v-if="pedido.status == 4">
              <b-btn variant="primary" block @click="openConfirmIfood(pedido)">
                <b-icon-check-circle /> CONFIRMAR NO IFOOD
              </b-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mt-3 p-1">
              <button
                class="btn btn-outline-secondary btn-block"
                @click="openMap(pedido)"
              >
                <i class="fa fa-map"></i> <span class="">Mapa/Rota</span>
              </button>
            </div>
            <div class="col-6 mt-3 p-1">
              <button
                class="btn btn-outline-info btn-block"
                @click="openPgto(pedido)"
              >
                <i class="fa fa-money-bill-alt"></i> <span class="">Pgto</span>
              </button>
            </div>
            <div class="col-6 m-0 mt-1 p-1">
              <!-- <button
                class="btn btn-outline-danger btn-block"
                @click="problema(pedido)"
              >
                <i class="fa fa-exclamation-triangle"></i>
                Problema
              </button> -->
            </div>
            <div class="col-6 m-0 mt-1 p-1">
              <button
                class="btn btn-block"
                :class="{
                  'btn-success': pedido.status != 4,
                  'btn-warning': pedido.status == 4,
                }"
                @click="
                  marcarConcluido(
                    pedido,
                    pedido.status == 4 ? 'desconfirm' : 'confirm'
                  )
                "
              >
                <span v-if="pedido.status != 4">
                  <i class="fa fa-check"></i> <span class="">Entregue</span>
                </span>
                <span v-if="pedido.status == 4">
                  <i class="fa fa-chevron-left"></i>
                  <span class=""> Rota</span>
                </span>
              </button>
            </div>
            <div class="col-12 mt-3 text-center" v-if="pedido.pedido_erro == 1">
              <div class="bg-danger text-white text-center p-3">
                <i class="fa fa-exclamation-triangle"></i> Entrega com problema
                <br />
                <small>{{ pedido.motivo_erro }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: 200px;
          display: block;
          background-color: white;
        "
      ></div>
      <b-modal id="modal-problema">
        <template #modal-title>
          <i class="fa fa-exclamation-triangle"></i>
          Problema na Entrega {{ pedido_problema.senha }}
        </template>
        <div class="text-left">
          <button
            @click="alertarProblema('Não Atende')"
            class="btn btn-block btn-danger text-left"
          >
            <i class="fa fa-store-slash"></i> Não atende
          </button>
          <button
            @click="alertarProblema('Pedido Errado')"
            class="btn btn-block btn-danger text-left"
          >
            <i class="fa fa-cash-register"></i> Pedido errado
          </button>
          <button
            @click="alertarProblema('Reclamou Temperatura')"
            class="btn btn-block btn-danger text-left"
          >
            <i class="fa fa-thermometer-quarter"></i> Reclamou Temperatura
          </button>
          <button
            @click="alertarProblema('Faltou Itens')"
            class="btn btn-block btn-danger text-left"
          >
            <i class="fa fa-wine-bottle"></i> Faltou Itens
          </button>
          <button
            @click="alertarProblema('Outros')"
            class="btn btn-block btn-danger text-left"
          >
            <i class="fa fa-exclamation-triangle"></i> Outros
          </button>

          <button
            @click="alertarProblema('')"
            class="btn btn-block btn-warning text-left bt-3"
            v-if="pedido_problema && pedido_problema.pedido_erro == 1"
          >
            <i class="fa fa-trash"></i> Retirar Problema
          </button>
        </div>
        <template #modal-footer>
          <button
            class="btn btn-secondary btn-block"
            @click="
              $bvModal.hide('modal-problema');
              this.pedido_problema = null;
            "
          >
            <i class="fa fa-times"></i> Fechar
          </button>
        </template>
      </b-modal>
      <b-modal
        id="modal-open-telefone"
        hide-header
        hide-footer
        no-close-on-esc
        no-enforce-focus
      >
        <div v-if="dados_cliente">
          <h4>{{ dados_cliente.Nome }}</h4>
          <h5>{{ dados_cliente.Telefone }}</h5>
          <hr />
          <b-btn
            variant="success"
            block
            @click="openWPP(dados_cliente.Telefone, 'tel')"
            ><b-icon-phone /> Telefonar</b-btn
          >
          <b-btn
            variant="success"
            block
            @click="openWPP(dados_cliente.Telefone, 'wpp')"
            ><i class="fab fa-whatsapp mr-1"></i> Whatsapp</b-btn
          >
          <b-btn
            variant="success"
            block
            @click="openWPP(dados_cliente.Telefone, 'cp')"
            ><i class="fa fa-copy ml-3"></i> Copiar Numero</b-btn
          >
          <b-btn
            variant="secondary"
            block
            @click="openWPP(dados_cliente.Telefone, 'close')"
            ><i class="fa fa-door-open ml-3"></i> Fechar</b-btn
          >
        </div>
      </b-modal>
    </div>
    <input type="hidden" id="testing-code" :value="endereco_copy" />
    <input type="hidden" id="integration-code" :value="localizador" />
    <input
      type="hidden"
      id="cellphone"
      v-if="dados_cliente && dados_cliente.Telefone"
      :value="dados_cliente.Telefone"
    />
  </pull-to>
</template>
<style></style>
<script>
import PullTo from "vue-pull-to";
import axios from "@/utils/axios";

import moment from "moment";

export default {
  components: {
    PullTo,
  },
  data() {
    return {
      pedidos: [],
      loading: false,
      interval: null,
      alertaConexao: false,
      t: "asda",
      TOP_DEFAULT_CONFIG: {
        pullText: "arraste e solte para atualizar", // The text is displayed when you pull down
        triggerText: "arraste e solte para atualizar", // The text that appears when the trigger distance is pulled down
        loadingText: "atualizando...", // The text in the load
        doneText: "atualizado!", // Load the finished text
        failText: "f", // Load failed text
        loadedStayTime: 400, // Time to stay after loading ms
        stayDistance: 50, // Trigger the distance after the refresh
        triggerDistance: 70, // Pull down the trigger to trigger the distance
      },
      pedido_problema: null,
      endereco_copy: "",
      data: this.$store.state.data,
      dados_cliente: null,
      localizador: "",
    };
  },
  mounted() {
    this.carregar();
  },
  created() {
    console.log(2);
  },
  watch: {
    "$route.name": function() {
      this.pedidos = [];
      this.cancelInterval();
      this.$nextTick(() => {
        this.carregar();
      });
    },
    "$store.state.data": function() {
      this.data = this.$store.state.data;
      this.carregar();
    },
  },
  methods: {
    openConfirmIfood(pedido) {
      console.log(123, pedido);
      this.copiarLocalizador(
        pedido.integration_data.customer.phone.localizer.toString()
      );
      window.open("https://confirmacao-entrega-propria.ifood.com.br/");
    },
    openWPP(tel, opt) {
      if (opt == "wpp") {
        window.location.href =
          "https://wa.me/55" + (tel.substring(0, 2) != "16" ? "16" : "") + tel;
      } else if (opt == "tel") {
        window.location.href =
          "tel:" + (tel.substring(0, 2) != "16" ? "16" : "") + tel;
      } else if (opt == "cp") {
        let testingCodeToCopy = document.querySelector("#cellphone");
        testingCodeToCopy.setAttribute("type", "text");
        testingCodeToCopy.select();

        try {
          var successful = document.execCommand("copy");
          if (successful) {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            Toast.fire({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: false,
              icon: "success",
              title: "Telefone Copiado!",
            });
          }
        } catch (err) {
          alert("Oops, unable to copy");
        }

        /* unselect the range */
        testingCodeToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      } else if (opt == "close") {
        this.$bvModal.hide("modal-open-telefone");
      }
    },
    openCliente(dados) {
      this.dados_cliente = dados;
      if (dados && dados.Nome) {
        this.$bvModal.show("modal-open-telefone");
      }
    },
    async alertarProblema(problema) {
      if (this.pedido_problema) {
        const res = await axios.post("pedido-change", {
          type: "problema",
          problema,
          ...this.pedido_problema,
        });
        if (res && res.data) {
          if (res.data.success) {
            this.$swal({
              title: "Problema Informado!",
              icon: "success",
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: false,
            });
          } else if (res.data.success === 0) {
            this.$swal({
              title: "Pedido já foi re-feito pelo caixa",
              icon: "error",
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: false,
            });
          }
          this.$bvModal.hide("modal-problema");
        }
        this.carregar();
      }
    },
    async problema(pedido) {
      if (pedido) {
        this.pedido_problema = pedido;
        this.$bvModal.show("modal-problema");
      }
    },
    cancelInterval() {
      try {
        clearInterval(this.interval);
        this.interval = null;
      } catch (e) {
        console.log(e);
      }
    },
    async marcarConcluido(pedido, tipo) {
      const resp = await this.$swal.fire({
        title: "Confirmação",
        icon: "question",
        text: `Você tem certeza que deseja marcar esta entrega como ${
          tipo == "confirm" ? "CONCLUIDO" : "EM ROTA"
        }?`,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        showValidationMessage: true,
        confirmButtonText: "SIM",
        cancelButtonText: "Canc.",
        preConfirm: async () => {
          return true;
        },
      });

      if (resp.isConfirmed) {
        await axios
          .post("pedido-change", {
            type: tipo,
            ...pedido,
          })
          .then((data) => {
            console.log("pedido ok ", pedido);

            this.carregar();

            if (data && data.data && data.data.success) {
              this.$swal({
                title: "Pedido Alterado!",
                icon: "success",
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: false,
              });
              if (pedido.sistema == "IFOOD" && pedido.integration_data) {
                if (
                  pedido.integration_data &&
                  pedido.integration_data.customer &&
                  pedido.integration_data.customer.phone &&
                  pedido.integration_data.customer.phone.localizer
                ) {
                  console.log("ok");
                  this.$swal({
                    title: "CONFIRME NO IFOOD",
                    html:
                      "CÓDIGO LOCALIZADOR<hr/> " +
                      pedido.integration_data.customer.phone.localizer +
                      "<hr/>",
                    icon: "info",
                    //showConfirmButton: false,
                    //timer: 20000
                  }).then(() => {
                    this.copiarLocalizador(
                      pedido.integration_data.customer.phone.localizer
                    );
                    window.open(
                      "https://confirmacao-entrega-propria.ifood.com.br/"
                    );
                    this.$swal(
                      "Código Localizador Copiado",
                      pedido.integration_data.customer.phone.localizer,
                      "info"
                    );
                  });
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      console.log(resp, pedido);
    },

    async openPgto(pedido) {
      console.log(pedido);

      const { value: formValues } = await this.$swal.fire({
        title: "<i class='fa fa-money-bill-alt'></i> &nbsp; Pagamento",
        showCancelButton: true,
        allowEscapeKey: true,
        confirmButtonText: "Salvar",
        cancelButtonText: "Canc.",
        allowOutsideClick: false,
        html: `
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"><i class='fa fa-dollar-sign'></i> &nbsp; Total</span>
            <input type="number" v-money='moneyMask' step=".1" id="input-total" pattern='\\d*'  disabled class="form-control text-center" value="${pedido.vl_total.toFixed(
              2
            )}">
          </div>
           <div class="input-group mb-3">
           
            <span class="input-group-text" id="basic-addon1"><i class='fa fa-money-bill'></i> &nbsp; Din. &nbsp; </span>
            <input type="number" 
            onKeyUp='
               let vl =  
              (parseFloat(document.getElementById("input-dinheiro").value) + 
              parseFloat(document.getElementById("input-cartao").value) - 
              parseFloat(${pedido.vl_total}));

              if(vl>parseFloat(document.getElementById("input-dinheiro").value) ){
                vl = parseFloat(document.getElementById("input-dinheiro").value);
              }

              if(!vl || vl<0){
                vl = 0;
              }

              document.getElementById("input-troco").value = vl.toFixed(2);
              '
              
              pattern='\\d*' 
            onBlur="this.value = parseFloat(this.value).toFixed(2)" 
            onFocus="this.select()"
            id="input-dinheiro" class="form-control text-center" value="${pedido.vl_dinheiro.toFixed(
              2
            )}" placeholder="Valor Dinheiro">
          </div>
                     <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"><i class='fa fa-coins'></i> &nbsp; Troco</span>
            <input type="number" pattern='\\d*' 
            onchange=""
            id='input-troco' class="form-control text-center" value="${pedido.vl_troco.toFixed(
              2
            )}" disabled placeholder="Valor Troco">
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"><i class='fab fa-cc-visa'></i> &nbsp; Cart.  &nbsp; </span>
            <input type="number" pattern='\\d*'
             onKeyUp='
              let vl =  
              (parseFloat(document.getElementById("input-dinheiro").value) + 
              parseFloat(document.getElementById("input-cartao").value) - 
              parseFloat(${pedido.vl_total}));

              if(!vl || vl<0){
                vl = 0;
              }

              if(vl>parseFloat(document.getElementById("input-dinheiro").value) ){
                vl = parseFloat(document.getElementById("input-dinheiro").value);
              }

              

              document.getElementById("input-troco").value = vl.toFixed(2);
              
              '
             onBlur="this.value = parseFloat(this.value).toFixed(2)" 
            onFocus="this.select()"
             id="input-cartao" class="form-control text-center" 
             value="${pedido.vl_cartao.toFixed(2)}">
          </div>
        `,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          let err = false;
          let vlTotal = 0;

          if (
            document.getElementById("input-dinheiro").value.trim() == "" ||
            (document.getElementById("input-dinheiro").value != 0 &&
              !parseFloat(
                document.getElementById("input-dinheiro").value.trim()
              ))
          ) {
            this.$swal.showValidationMessage(
              `Informe um valor válido para dinheiro.`
            );
            err = true;
          } else {
            vlTotal += parseFloat(
              document.getElementById("input-dinheiro").value.trim()
            );
          }
          if (
            document.getElementById("input-cartao").value.trim() == "" ||
            (document.getElementById("input-cartao").value != 0 &&
              !parseFloat(document.getElementById("input-cartao").value.trim()))
          ) {
            this.$swal.showValidationMessage(
              `Informe um valor válido para cartão.`
            );
            err = true;
          } else {
            vlTotal += parseFloat(
              document.getElementById("input-cartao").value.trim()
            );
          }

          if (vlTotal < parseFloat(pedido.vl_total)) {
            this.$swal.showValidationMessage(
              `O valor não bate com o total do pedido`
            );
            err = true;
          }

          if (!err) {
            return {
              success: true,
              vl_dinheiro: parseFloat(
                document.getElementById("input-dinheiro").value.trim()
              ),
              vl_cartao: parseFloat(
                document.getElementById("input-cartao").value.trim()
              ),
              vl_troco: parseFloat(
                document.getElementById("input-troco").value.trim()
              ),
            };
          }
        },
      });

      if (formValues && formValues.success) {
        this.cancelInterval();
        await axios
          .post("pedido-change", {
            type: "valor",
            vl_dinheiro_new: formValues.vl_dinheiro,
            vl_cartao_new: formValues.vl_cartao,
            ...pedido,
          })
          .then((x) => {
            if (x.data.success) {
              this.$swal({
                title: "Pedido Alterado!",
                icon: "success",
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: false,
              });
              this.carregar();
            }
          })
          .catch((err) => {
            console.log(err);
          });

        console.log(pedido);
      }
    },
    openMap(pedido) {
      if (
        /* if we're on iOS, open in Apple Maps */
        navigator.platform.indexOf("iPhone") != -1 ||
        navigator.platform.indexOf("iPad") != -1 ||
        navigator.platform.indexOf("iPod") != -1
      )
        window.open(
          `maps://maps.google.com/maps?daddr=${pedido.logradouro}, ${pedido.numero} - ${pedido.bairro}`
        );
      else
        window.open(
          `https://maps.google.com/maps?daddr=${pedido.logradouro}, ${pedido.numero} - ${pedido.bairro}`
        );
    },
    onRefresh: async function(loaded) {
      await this.carregar();
      loaded("done");
    },
    async copiarLocalizador(localizador) {
      await this.copiarEndereco(localizador);
      // console.log(endereco);
      this.localizador = localizador.toString();

      this.$nextTick();
      this.$forceUpdate();
      let testingCodeToCopy = document.querySelector("#integration-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        if (successful) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: false,
            icon: "success",
            title: "Código confirmador Copiado!",
          });
        }
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    copiarEndereco(endereco) {
      console.log(endereco);
      this.endereco_copy = endereco;
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        if (successful) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: false,
            icon: "success",
            title: "Endereço Copiado!",
          });
        }
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    //https://github.com/lakb248/vue-pull-refresh
    async carregar() {
      // console.log("atu");
      const me = this;

      me.loading = true;

      me.$nextTick(async () => {
        let data = moment(this.data.format("YYYY-MM-DD"));
        const dataHora = moment(this.data.format("YYYY-MM-DD") + " 00:00:00");
        if (data.isBefore(dataHora)) {
          data = data.add(-1, "d");
        }
        const consulta = {
          dt: data.format("YYYY-MM-DD") + " 00:00:00",
          dt_final: data.add(1, "d").format("YYYY-MM-DD") + " 08:00:00",
          finalizado: me.$route.name == "Realizados",
        };
        console.log(consulta);
        await axios
          .post("pedidos", consulta)
          .then((data) => {
            console.log(111, data);

            me.alertaConexao = false;
            me.cancelInterval();
            me.pedidos = data.data;
            for (let p of me.pedidos) {
              if (p.dados_cliente && p.dados_cliente.Logradouro) {
                p.endereco =
                  (p.dados_cliente.Logradouro
                    ? p.dados_cliente.Logradouro
                    : "") +
                  (p.dados_cliente.Numero && p.dados_cliente.Numero.length > 0
                    ? ", " + p.dados_cliente.Numero
                    : "");
                p.endereco +=
                  p.endereco.trim().length > 0
                    ? " - " + p.dados_cliente.Bairro
                    : "";
                p.bairro = p.dados_cliente.Bairro;
                p.logradouro = p.dados_cliente.Logradouro;
                p.numero = p.dados_cliente.Numero;
                p.cep = p.dados_cliente.CEP;

              } else {
                p.endereco =
                  (p.logradouro ? p.logradouro : "") +
                  (p.numero && p.numero.length > 0 ? ", " + p.numero : "");
                p.endereco +=
                  p.endereco.trim().length > 0 ? " - " + p.bairro : "";
              }
            }
            me.$nextTick(() => {
              me.loading = false;
              // resolve();
              // this.interval = setTimeout(() => {
              //   this.carregar();
              // }, 1500);
            });
          })
          .catch(() => {
            me.alertaConexao = true;
            // reject();
            // this.interval = setInterval(() => {
            //   this.carregar();
            // }, 1000);
          });
      });
    },
  },
};
</script>
